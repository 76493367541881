import i18n from "@/i18n.js"

const columnDefs = [{
        headerName: `${i18n.t('custom_email.table_id')}`,
        traslateName: "custom_email.table_id",
        field: "id",
        sortable: true,
        filter: true,
        hide: false,
        showKey: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerCheckboxSelection: true,
        filterParams: {
            clearButton: true,
        },
        width: 20,
    },
    {
        headerName: `${i18n.t('custom_email.table_name')}`,
        traslateName: "custom_email.table_name",
        field: "name",
        sortable: true,
        filter: true,
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            clearButton: true,
        },
        width: 55,
    },
    {
        headerName: `${i18n.t('custom_email.table_company')}`,
        traslateName: "custom_email.table_company",
        field: "company",
        sortable: true,
        filter: true,
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            clearButton: true,
        },
        width: 55,
    },
    {
        headerName: `${i18n.t('custom_email.table_state')}`,
        traslateName: "custom_email.table_state",
        field: "status",
        sortable: true,
        filter: true,
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            clearButton: true,
        },
        width: 40,
    },
    {
        headerName: `${i18n.t('custom_email.table_date')}`,
        traslateName: "custom_email.table_date",
        field: "created_at",
        sortable: true,
        filter: "agDateColumnFilter",
        hide: false,
        showKey: true,
        checkboxSelection: false,
        headerCheckboxSelectionFilteredOnly: false,
        headerCheckboxSelection: false,
        filterParams: {
            comparator: (filterLocalDateAtMidnight, // eslint-disable-line
                cellValue) => { // eslint-disable-line
                let dateAsString = cellValue;
                if (dateAsString == null) return -1;
                let dateParts = dateAsString.split("/");
                let cellDate = new Date(
                    Number(dateParts[2]),
                    Number(dateParts[1]) - 1,
                    Number(dateParts[0])
                );
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                    return 0;
                }
                if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                }
                if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                }
            },
            browserDatePicker: true,
        },
        width: 40,
    }
]

const mergeTagsConfig = {
    designTagsConfig: {
        delimiter: ['{{', '}}']
    }
}

const mergeTags = {
    first_name: {
        name: `${i18n.t('form.name')}`,
        value: "{{NOMBRE}}",
    },
    last_name: {
        name: `${i18n.t('form.last_name')}`,
        value: "{{APELLIDO}}",
    },
    delivery_time: {
        name: `${i18n.t('form.delivery_time')}`,
        value: "{{TIEMPO ENTREGA}}",
    },
    delivery_date: {
        name: `${i18n.t('form.delivery_date')}`,
        value: "{{FECHA ENTREGA}}",
    },
    state: {
        name: `${i18n.t('form.state')}`,
        value: "{{ESTADO}}",
    },
    order_code: {
        name: `${i18n.t('form.order_number')}`,
        value: "{{NUMERO PEDIDO}}",
    },
    guia_pedido: {
      name: "Número de guia del pedido",
      value: "{{GUIA PEDIDO}}",
    },
    url_pedido: {
      name: "URL SEGUIMIENTO",
      value: "{{URL SEGUIMIENTO}}",
    },
    code_seguimiento: {
      name: "CODIGO SEGUIMIENTO",
      value: "{{CODE SEGUIMIENTO}}",
    },
    observaciones: {
      name: "OBSERVACIONES MOVIL",
      value: "{{OBSERVACIONES}}",
    },
    placa: {
      name: "PLACA",
      value: "{{PLACA}}",
    },
    nombre_conductor: {
      name: "NOMBRE CONDUCTOR",
      value: "{{CONDUCTOR}}",
    }, 
    cedi: {
      name: "CEDI",
      value: "{{CEDI}}",
    },
    observaciones_plataforma: {
      name: "OBSERVACIONES PLATAFORMA",
      value: "{{OBSERVACIONES_PLATAFORMA}}",
    }
}
export default {
    columnDefs,
    mergeTagsConfig,
    mergeTags,
}