<template>
  <vs-popup
    id="popupStatusForm"
    :title="$t('status.form')"
    :active.sync="popup"
  >
    <div class="vx-row mb-4">
      <div class="vx-col sm:w-1/2 w-full mb-2">
        <label class="vs-select--label">{{ $t("users.company") }}</label>
        <v-select
          :options="companies"
          label="name"
          :clearable="false"
          name="company"
          :data-vv-as="$t('users.company')"
          v-validate="'required'"
          v-model="company"
          :placeholder="$t('users.company')"
          autocomplete="off"
        >
          <template slot="option" slot-scope="option">{{
            option.name | capitalize
          }}</template>
        </v-select>
        <span class="text-danger text-sm" v-show="errors.has('company')">{{
          errors.first("company")
        }}</span>
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-2">
        <label class="vs-select--label">{{ $t("users.status") }}</label>
        <v-select
          :options="statuses"
          label="name"
          name="state"
          :data-vv-as="$t('users.status')"
          v-validate="'required'"
          :clearable="false"
          :placeholder="$t('users.status')"
          v-model="status"
          autocomplete="off"
        >
          <template slot="option" slot-scope="option">{{
            option.name | capitalize
          }}</template>
          <div slot="no-options">{{ $t("sms.select_company") }}</div>
        </v-select>
        <span class="text-danger text-sm" v-show="errors.has('state')">{{
          errors.first("state")
        }}</span>
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-2">
        <label class="vs-select--label">{{ $t("manage_news.news") }}</label>
        <!-- v-model="newsSelected"
            :options="newsOptions" -->
            <multiselect
            v-model="newsSelected"
            placeholder='Novedades'
            label="name"
            :options="newsOptions"
            :multiple="true"
            track-by="name"
            >
          <!-- <template slot="option" slot-scope="option">{{
            option.name | capitalize
          }}</template>-->
          <div slot="no-options">{{ $t("manage_news.noSelected") }}</div>
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('state')">{{
          errors.first("state")
        }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-1/1 w-full mb-2">
        <label class="vs-select--label flex justify-center">{{
          $t("custom_email.message_save_template")
        }}</label>
      </div>
      <div class="vx-col w-1/1 w-full mb-3 flex justify-center">
        <vs-input :label-placeholder="$t('form.subject')" v-model="name" />
      </div>
    </div>
    <div class="vx-row">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-button
          class="mt-6 block offset-3"
          color="primary"
          type="filled"
          :disabled="isSaved"
          @click="setSave"
          >{{ $t("devices.save") }}</vs-button
        >
      </vs-col>
    </div>
  </vs-popup>
</template>

<script>
import HTTP from "@/http-common";
import StatusServices from "@/api/status/Status";
import Multiselect from "vue-multiselect"
export default {
  props: {
    email: {
      type: Object,
      required: false,
    },
    popupActive: {
      type: Boolean,
      required: true,
    },
    original_status_id: {
      type: Number,
      required: false,
      default: () => null,
    },
    original_name: {
      type: String,
      required: false,
      default: () => "",
    },
    original_company_id: {
      type: Number,
      required: false,
      default: () => null,
    },
    selectedNewsPrev:{
      type: [String, Array],
      required: false,
      default: () => "",
    }
  },
  components: {
    Multiselect
  },
  data() {
    return {
      companies: [],
      company: { id: null, name: null },
      statuses: [],
      newsOptions:[],
      status: { id: null, name: null },
      newsSelected:[],
      name: "",
    };
  },
  computed: {
    popup: {
      get() {
        return this.popupActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closePopup", {});
        }
      },
    },
    isSaved() {
      return !this.status.id || !this.company.id || this.name === "";
    },
  },
  watch: {
    async company(val) {
      if (val != null) {
        await this.getStatusCompany(val.version);
        if (this.original_status_id) {
          let aux;
          aux = this.statuses.filter(
            (item) => item.id === this.original_status_id
          );
          if (aux.length) this.status = aux[0];
        }
      }
    },
  },
  methods: {
    async getStatusCompany(version) {
      this.statuses = [];
      try {
        let response = await StatusServices.getStatus(version);

        this.statuses = response.data.map((row) => ({
          id: row.id,
          name: row.status,
        }));
        if (this.status_id) {
          let index = this.statuses.find(
            (element) => element.id === this.status_id
          );
          this.status = index[0];
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCompany() {
      try {
        const { data } = await HTTP.get("/v1/companies/companies/");
        this.companies = data.map((row) => ({
          id: row.id,
          name: row.company,
          version: row.version,
        }));
      } catch (error) {
        console.log(error);
      }
    },
    clear() {
      this.company_id = null;
      this.company = null;
      this.status_id = null;
      this.status = [];
      this.statuses = [];
      this.companies = [];
      this.newsSelected = []
    },
    setSave() {
      this.$emit("submitForm", {
        status_id: this.status.id,
        name: this.name.replace(/<p[^>]*>/g, "").replace(/<\/p>/g, ""),
        company_id: this.company.id,
        newsSelected:this.newsSelected.map(opcion => opcion.id)
      });
    },
    async getNewsOptions(){
      try {
        const  data  = await HTTP.get("newness/");
        let opciones= data.data.message.newnesses.map((novelty) => ({
          name: novelty.name,
          id: novelty.id
        }));
        this.newsOptions=opciones;
        let opcionesaseleccionadas = []
        opcionesaseleccionadas=opciones.filter(opc => {
          return this.selectedNewsPrev.includes(opc.id);
        })
        this.selectedNewsPrev===""  ?  this.newsSelected=[] : this.newsSelected = opcionesaseleccionadas
      } catch (error) {
        console.log(error);
      }
    }
  },
  async created() {
    let aux;
    this.getNewsOptions();
    await this.getCompany();
    if (this.original_company_id) {
      aux = this.companies.filter(
        (item) => item.id === this.original_company_id
      );
      if (aux.length) {
        this.company = aux[0];
      }
    }
    if (this.original_name !== "") {
      this.name = this.original_name;
    }
  },
  beforeDestroy() {
    var elem = document.querySelector("#popupStatusForm");
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.toggle-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vs-con-input-label {
  width: 400px !important;
}
</style>
<style>
.list-item {
  transition: all 0.5s;
}
#parameter-list .vx-card__body {
  height: 54vh;
  overflow-y: auto;
}
.multiselect__option--highlight {
  background: #FDD402;
  outline: none;
  color: #fff;
}

.multiselect__option--highlight:after, .multiselect__option--selected:after {
  display: none;
}
.multiselect__content-wrapper {
  font-size: 12px;
}
.multiselect__tag{
  background: #FDD402;
  font-size: 10px;
}
.multiselect__option{
  padding: 5px;
  min-height: 25px;
}
.multiselect__tag-icon:after,.multiselect__tag-icon{
  color: #fff;
}
.multiselect__tag-icon:hover {
    background: #d1b528c5;
}
</style>
